<template>
  <div style="padding: 0 0 5% 6%">
    <div class="planName">创建方案</div>
    <div class="f-bold">基础信息</div>
    <div class="flex_warp mb10">
      <div class="blocks2 flex_items flex_start">
        <span class="mr10 liTitle2 nature">方案属性</span>
        <el-select
          v-model="nature"
          placeholder="方案属性"
          class="w-140"
          filterable
        >
          <el-option
            v-for="(item, index) in attribute"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex_warp">
      <div class="blocks2 flex_items flex_start mb10">
        <span class="mr10 liTitle">方案类别</span>
        <el-select
          v-model="planKind"
          placeholder="方案类别"
          :clearable="true"
          class="w-140"
          filterable
          @change="kindChange"
        >
          <el-option
            v-for="(item, index) in planKinds"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="blocks2 flex_items flex_start mb10">
        <span class="mr10 liTitle">方案名称</span>
        <el-input
          v-model="planName"
          placeholder="请输入"
          class="w-140"
        ></el-input>
      </div>
      <div class="blocks2 flex_items flex_start mb10">
        <span class="mr10 liTitle">小区名称</span>
        <el-input
          v-model="estateName"
          placeholder="请输入"
          class="littleBox w-140"
          disabled
        ></el-input>
      </div>
      <div class="blocks2 flex_items flex_start mb10">
        <span class="mr10 liTitle" style="margin-right: -8px">户型</span>
        <el-select
          v-model="houseHold"
          placeholder="请选择"
          class="w-140"
          filterable
          disabled
        >
          <el-option
            v-for="(item, index) in houseHolds"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="blocks2 flex_items flex_start mb10">
        <span class="mr10 liTitle">预计工期</span>
        <el-input
          v-model="timeLimit"
          placeholder="请输入"
          class="w-140"
          type="number"
        ></el-input>
      </div>
    </div>
    <div class="flex_warp">
      <span class="mr10 liTitle">特色标签</span>
      <div class="uniquePr">
        <el-input
          v-model="item.title"
          placeholder="请输入内容"
          v-for="(item, index) in btnList"
          :key="index"
          class="w-140 uniqueTag"
        ></el-input>
        <el-button
          type="primary"
          size="small"
          plain
          @click="addInput"
          class="h-30"
          >添加标签</el-button
        >
      </div>
    </div>
    <div class="f-bold m-b-10">方案标签</div>
    <!-- flex: 0 0 30%; -->
    <div v-if="problems.length != 0" class="planTag">
      <div v-for="(item, index) in problems" :key="index" class="planTagC">
        <span class="f-bold f-16">{{ problems[0].title }}</span>
        <div class="tagChild">位置</div>
        <el-checkbox-group v-model="labelCheck">
          <el-checkbox-button
            v-for="(item1, index1) in item.position"
            :key="index1"
            :label="item1.id"
            >{{ item1.title }}</el-checkbox-button
          >
        </el-checkbox-group>
        <div class="tagChild">问题类型</div>
        <el-checkbox-group v-model="labelCheck1">
          <el-checkbox-button
            v-for="(item2, index2) in item.problem"
            :key="index2"
            :label="item2.id"
            >{{ item2.title }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div v-else class="planTagC2">--暂无数据，请先选择方案类别--</div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">温馨提示</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="tip"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">计价规则</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="priceRule"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">服务内容</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="serviceCon"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">使用材料</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="useMaterial"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">服务工期</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="serviceTime"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">完工验收</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="finished"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">质保信息</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="warrantyMsg"
        class="uniquePr"
      >
      </el-input>
    </div>
    <div class="flex_warp mb10">
      <span class="mr10 liTitle">方案简介</span>
      <el-input
        type="textarea"
        placeholder="请输入200字以内的描述"
        v-model="planIntro"
        class="uniquePr"
        maxlength="200"
      >
      </el-input>
    </div>
    <div class="flex_start">
      <div class="planListName">方案清单</div>
      <el-select
        v-model="city"
        placeholder="选择城市"
        :clearable="true"
        class="citySelect"
        filterable
        @change="cityChange"
      >
        <el-option
          v-for="item in citys"
          :key="item.city_id"
          :label="item.city"
          :value="item.city_id"
        ></el-option>
      </el-select>
      <el-button
        plain
        size="mini"
        type="primary"
        @click="addProject"
        class="addGroup"
        >+增加分组</el-button
      >
    </div>
    <div
      class="groupPr"
      v-for="(item, index) in project"
      :key="`${item.title}-${index}`"
    >
      <div class="flex_start">
        <el-image
          class="groupImg"
          src="https://imgaes.yiniaoweb.com/Icon/basicPlan/del.png"
          @click="sureDel(index)"
        ></el-image>
        <div @click="changeTitle(index)" class="titleChange groupName">
          {{ item.title }}
        </div>
      </div>
      <avue-crud :option="option" :data="item.data">
        <template slot-scope="scope" slot="menu">
          <div>
            <el-button
              size="mini"
              type="primary"
              plain
              @click="delDetail(scope.row, index)"
              >删除</el-button
            >
          </div>
        </template>
      </avue-crud>
      <el-button
        type="primary"
        plain
        size="small"
        class="addDetail"
        @click="addDetail(index)"
        >+增加清单项目</el-button
      >
    </div>
    <div class="f-bold m-b-10">图片展示</div>
    <el-upload
      action="https://public.yiniao.co/api/v1/upload"
      list-type="picture-card"
      :multiple="true"
      :on-remove="handleRemove"
      :on-preview="handlePictureCardPreview"
      :on-success="upsuccess"
      :file-list="fileList"
      :data="tuzhi"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-input
      class="imgName"
      v-model="item.title"
      v-for="item in imgs"
      :key="item.path"
    ></el-input>
    <el-dialog
      :visible.sync="tudiag"
      append-to-body
      center
      :key="imgNum"
      width="50%"
      @close="closeImg"
    >
      <div class="prDiv">
        <img width="100%" :src="dialogImageUrl" alt class="img" />
      </div>
    </el-dialog>
    <div class="checkResult">关联检测结果</div>
    <div class="flex_start">
      <div>
        <el-select
          v-model="result1"
          placeholder="选择问题"
          :clearable="true"
          class="w-150"
          filterable
          @change="getRelation"
          @focus="cityTip"
        >
          <el-option
            v-for="(item, index) in problemTitle"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-select
          v-model="result2"
          placeholder="结果"
          :clearable="true"
          class="checkResult"
          @change="result2Change"
          filterable
          multiple
          reserve-keyword
        >
          <el-option
            v-for="(item, index) in result2s"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="submitBtn">
      <el-button type="primary" plain size="medium" @click="referPlan"
        >提交</el-button
      >
    </div>
    <!-- 添加清单弹框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      append-to-body
      center
      :key="littleKey"
      @close="closeDialog"
    >
      <div class="addDetailForm">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="产品分类:"
            :label-width="formLabelWidth"
            style="color: #606266"
            prop="tao"
          >
            <el-select
              v-model="form.tao"
              placeholder="选择产品分类"
              :clearable="true"
              class="w-240"
              filterable
              @change="getTypes1"
            >
              <el-option
                v-for="(item, index) in taos"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.tao == 0 || form.tao == 1"
            label="类别名称:"
            :label-width="formLabelWidth"
            style="color: #606266"
            prop="firstType"
            key="firstType"
          >
            <el-select
              v-model="form.firstType"
              placeholder="类别名称"
              :clearable="true"
              class="w-240"
              filterable
              @change="getTypes2"
            >
              <el-option
                v-for="(item, index) in firstTypes"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.tao == 0 || form.tao == 1"
            label="项目名称:"
            :label-width="formLabelWidth"
            style="color: #606266"
            prop="projectId"
            key="projectId"
          >
            <el-select
              v-model="form.projectId"
              placeholder="项目名称"
              :clearable="true"
              class="w-240"
              filterable
              @change="getTypeDet"
            >
              <el-option
                v-for="(item, index) in projectIds"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- v-if="form.tao == 2" -->
          <el-form-item
            label="标题:"
            :label-width="formLabelWidth"
            prop="projectTitle"
            key="projectTitle"
            v-if="form.tao == 2"
          >
            <el-input
              v-model="form.projectTitle"
              autocomplete="off"
              class="w-240"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="单位:"
            :label-width="formLabelWidth"
            v-if="form.tao == 0 || form.tao == 1"
          >
            <el-input
              v-model="form.title"
              autocomplete="off"
              class="w-240"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="单位:"
            :label-width="formLabelWidth"
            prop="title"
            key="title"
            v-if="form.tao == 2"
          >
            <el-select
              v-model="form.title"
              placeholder="请选择单位"
              :clearable="true"
              class="w-240"
              filterable
            >
              <el-option
                v-for="(item, index) in titles"
                :key="index"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="单价:"
            :label-width="formLabelWidth"
            prop="projectMoney"
            key="projectMoney"
          >
            <el-input
              v-if="form.tao == 0 || form.tao == 1"
              v-model="form.projectMoney"
              autocomplete="off"
              class="w-240"
              type="number"
              disabled
            ></el-input>
            <el-input
              v-if="form.tao == 2"
              v-model="form.projectMoney"
              autocomplete="off"
              type="number"
              class="w-240"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="方量:"
            :label-width="formLabelWidth"
            prop="number"
            key="number"
          >
            <el-input
              v-model="form.number"
              autocomplete="off"
              class="w-240"
              @input="countPrice"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="总计金额:" :label-width="formLabelWidth">
            <el-input
              v-model="form.moneyCount"
              autocomplete="off"
              class="w-240"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="清单备注:" :label-width="formLabelWidth">
            <el-input
              v-model="form.remarks"
              autocomplete="off"
              class="w-240"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            class="mr20 submitAddList"
            type="primary"
            plain
            size="small"
            @click="submitDetail('form')"
            >提 交</el-button
          >
          <el-button size="small" @click="dialogTypeCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改项目名称弹框 -->
    <el-dialog
      title="修改分组名称"
      :visible.sync="changeTitleDialog"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <div>
        <el-input placeholder="请输入修改名称" v-model="afterTitle"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTitleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeTitle">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提醒是否删除项目 -->
    <el-dialog
      title="是否删除"
      :visible.sync="delDialog"
      width="30%"
      append-to-body
    >
      <span>确定要删除该项目吗？（该项目下所有清单也会被一并删除）</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog = false">取 消</el-button>
        <el-button type="primary" @click="delProject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["currentPage"],
  data() {
    let relusList = (rules, value, callback) => {
      let regExp = /^[1-9]\d*$/;
      if (!this.form.number) {
        return callback(new Error("该项为必填项"));
      } else if (regExp.test(this.form.number) === false) {
        callback(new Error("只能输入大于等于1的正整数"));
      } else {
        return callback();
      }
    };
    let relusPrice = (rules, value, callback) => {
      let regExp = /^[1-9]\d*/;
      if (!this.form.projectMoney) {
        return callback(new Error("该项为必填项"));
      } else if (regExp.test(this.form.projectMoney) === false) {
        callback(new Error("只能输入大于等于1的正整数"));
      } else {
        return callback();
      }
    };
    return {
      rules: {
        tao: [{ required: true, message: "请选择产品分类", trigger: "change" }],
        firstType: [
          { required: true, message: "请选择类别名称", trigger: "change" },
        ],
        projectId: [
          { required: true, message: "请选择项目名称", trigger: "change" },
        ],
        number: [{ required: true, validator: relusList, trigger: "blur" }],
        projectTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        title: [{ required: true, message: "请选择单位", trigger: "change" }],
        projectMoney: [
          { required: true, validator: relusPrice, trigger: "blur" },
        ],
      },
      projectIndex: 0,
      littleKey: 1,
      imgNum: 100,
      nature: 2,
      attribute: [],
      planKind: "",
      planKinds: [],
      planName: "",
      estateName: "全部",
      house: [],
      houseHold: 9,
      houseHolds: [],
      timeLimit: "",
      btnList: [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
      picName: "",
      arr: [],
      tip: "",
      priceRule: "",
      serviceCon: "",
      useMaterial: "",
      serviceTime: "",
      finished: "",
      warrantyMsg: "",
      planIntro: "",
      problems: [],
      problemArr: [],
      labelCheck: [],
      labelCheck1: [],
      citys: [],
      list: [
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
      ],
      taos: [
        {
          id: 0,
          title: "套餐",
        },
        {
          id: 1,
          title: "单个项目",
        },
        {
          id: 2,
          title: "自定义",
        },
      ],
      firstTypes: [],
      projectIds: [],
      option: {
        // border: true,
        size: "medium",
        menu: true,
        page: false,
        align: "center",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, // 列显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          {
            label: "项目ID",
            prop: "projectId",
            hide: true,
          },
          {
            label: "类别",
            prop: "tao",
            hide: true,
          },
          {
            label: "是否代购",
            prop: "agency",
            hide: true,
          },
          {
            label: "名称",
            prop: "projectTitle",
          },
          {
            label: "单位",
            prop: "title",
          },
          {
            label: "单价",
            prop: "projectMoney",
          },
          {
            label: "方量",
            prop: "number",
          },
          {
            label: "总价金额",
            prop: "moneyCount",
          },
          {
            label: "备注",
            prop: "remarks",
          },
        ],
      },
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      fileList: [],
      result1: "",
      result2: [],
      problemTitle: [],
      result2s: [],
      formLabelWidth: "200px",
      city: "",
      project: [
        {
          title: "默认项目分组1",
          data: [],
        },
      ],
      titles: [],
      form: {
        tao: "",
        firstType: "",
        projectMoney: "",
        number: 1,
        moneyCount: "",
        projectTitle: "",
        remarks: "",
        projectId: "",
        title: "",
        agency: 0,
      },
      dialogFormVisible: false,
      chooseList: [],
      btnArr: [],
      arrType: [],
      planKindArr: [],
      houseType: [],
      changeTitleDialog: false,
      afterTitle: "",
      delDialog: false,
      imgTitle: [],
    };
  },
  mounted() {
    this.getChoice();
    this.getcitys();
  },
  methods: {
    result2Change() {
      //console.log(this.result2, "result2+++++++");
    },
    // 城市
    getcitys() {
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    cityTip() {
      if (this.city == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请先选择城市！",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
    },
    getRelation() {
      if (this.city == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请先选择城市！",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      this.result2 = "";
      this.result2s = [];
      this.util
        .get(this.HOST + "/Diagnostic/relation", {
          goods_category: this.result1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.result2s = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    cityChange() {
      this.form.tao = "";
      this.form.firstType = "";
      this.form.projectId = "";
      this.getTitles(this.city);
      this.getUnit(this.city);
    },
    // 关联结果问题
    getTitles(city) {
      if (city) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: city,
          })
          .then((res) => {
            if (res.code == 200) {
              this.problemTitle = res.data;
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    // 获取单位
    getUnit(city) {
      this.util
        .get(this.HOST + "/Diagnostic/Basics", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.titles = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getTypes1() {
      this.form.firstType = "";
      this.form.projectId = "";
      this.form.title = "";
      this.form.projectMoney = "";
      this.form.number = "";
      this.form.moneyCount = "";
      this.form.remarks = "";
      this.form.projectTitle = "";
      this.util
        .get(this.HOST + "/Diagnostic/Package", {
          city_id: this.city,
          type: this.form.tao,
        })
        .then((res) => {
          if (res.code == 200) {
            this.firstTypes = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getTypes2() {
      this.arrType = [];
      this.form.projectId = "";
      this.form.title = "";
      this.form.projectMoney = "";
      this.form.number = "";
      this.form.moneyCount = "";
      this.form.remarks = "";
      this.firstTypes.map((item) => {
        if (item.id == this.form.firstType) {
          this.arrType.push(item.data);
        }
        return (this.projectIds = this.arrType[this.arrType.length - 1]);
      });
    },
    getTypeDet() {
      this.util
        .get(this.HOST + "/Diagnostic/PackageInfo", {
          city_id: this.city,
          type: this.form.tao,
          id: this.form.projectId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.form.title = res.data.title;
            this.form.number = 1;
            if (res.data.artificial) {
              this.form.projectMoney = res.data.artificial;
            } else {
              this.form.projectMoney = res.data.prices;
            }
            this.form.moneyCount = this.form.number * this.form.projectMoney;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    countPrice() {
      this.form.moneyCount = this.form.projectMoney * this.form.number;
    },
    delDetail(row, index) {
      for (var i = this.project[index].data.length - 1; i > -1; i--) {
        if (this.project[index].data[i].$index == row.$index) {
          this.project[index].data.splice(row.$index, 1);
        }
      }
    },
    lookPlanIntro() {},
    addInput() {
      var myobject = { title: "" };
      this.btnList.push(myobject);
    },
    getproblems() {
      this.util
        .post(this.HOST + "/Diagnostic/schemeLabe", {
          param1: this.planKind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.problems = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getChoice() {
      this.$loading(this.util.opload);
      this.util.get(this.HOST + "/Diagnostic/all", {}).then((res) => {
        if (res.code == 200) {
          this.attribute = res.data.attribute;
          this.planKinds = res.data.label;
          this.houseHolds = res.data.HouseData;
          this.$loading().close();
        } else {
          this.$loading().close();
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    kindChange() {
      this.getproblems();
    },
    // 图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handleRemove(file, fileList) {
      for (let i = 0; i < this.imgs.length; i++) {
        if (file.name == this.imgs[i].title) {
          this.imgs.splice(i, 1);
        }
      }
      // this.imgs = fileList;
    },
    handlePictureCardPreview(file) {
      this.imgNum++;
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      //console.log(file, "file+++");
      this.imgs.push({
        title: file.name,
        path: response.data.file.path,
      });
      // this.imgs = fileList;
      // //console.log(this.imgs, "imgs+++");
    },
    sureDel(index) {
      if (this.project.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个项目！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.delDialog = true;
      this.projectIndex = index;
    },
    addProject() {
      let obj = {
        title: "默认项目分组" + Number(this.project.length + 1),
        data: [],
      };
      this.project.push(obj);
      this.$message({
        showClose: true,
        message: "增加分组成功！",
        type: "success",
        duration: 1200,
        offset: 90,
      });
    },
    delProject() {
      if (this.project.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个项目！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.project.splice(this.projectIndex, 1);
      this.delDialog = false;
    },
    addDetail(index) {
      // 打开添加清单模态框
      //console.log(this.form.projectTitle, "000");
      this.projectIndex = index;
      if (this.city == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请先选择城市！",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      this.dialogFormVisible = true;
      this.$nextTick(
        (this.form.tao = ""),
        (this.form.firstType = ""),
        (this.form.title = ""),
        (this.form.projectMoney = ""),
        (this.form.number = ""),
        (this.form.moneyCount = ""),
        (this.form.projectId = ""),
        (this.form.remarks = ""),
        (this.form.projectTitle = "")
      );
    },
    changeTitle(index) {
      this.projectIndex = index;
      this.afterTitle = this.project[index].title;
      this.changeTitleDialog = true;
    },
    submitChangeTitle() {
      if (this.afterTitle == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入修改项目名称！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.project[this.projectIndex].title = this.afterTitle;
      this.changeTitleDialog = false;
    },
    submitDetail(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 添加清单项目
          this.littleKey++;
          if (this.form.projectId != "") {
            this.projectIds.map((item) => {
              if (item.id == this.form.projectId) {
                this.form.projectTitle = item.name;
              }
              if (this.form.firstType == 128) {
                this.form.agency = 1;
              }
            });
          }
          let form = JSON.parse(JSON.stringify(this.form));
          this.project[this.projectIndex].data.push(form);
          this.dialogFormVisible = false;
          this.firstTypes = [];
          this.projectIds = [];
          this.form.projectTitle = "";
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.littleKey++;
      this.dialogFormVisible = false;
    },
    dialogTypeCancel() {
      this.littleKey++;
      this.firstTypes = [];
      this.projectIds = [];
      this.dialogFormVisible = false;
    },
    // 提交方案
    referPlan() {
      if (this.planKind == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案类别！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.planName == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入方案名称！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.timeLimit == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入预计工期！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.project.map((item) => {
        item.data.map((item1) => {
          delete item1.$index;
          delete item1.moneyCount;
        });
        return item;
      });

      // return;
      // 提交整个页面的数据
      let houseTitle = "";
      if (this.houseHold != "") {
        this.houseHolds.map((item) => {
          if (this.houseHold == item.id) {
            houseTitle = item.title;
          }
        });
        this.houseType.push({ id: this.houseHold, title: houseTitle });
      }
      if (this.estateName != "") {
        this.house.push({ id: 0, area: 0, name: this.estateName });
      } else {
        this.house = [];
      }
      let planKindName = "";
      this.planKinds.map((item) => {
        if (this.planKind == item.id) {
          planKindName = item.title;
        }
      });
      this.planKindArr.push({ id: this.planKind, title: planKindName });
      for (let i = 0; i < this.btnList.length; i++) {
        this.btnList = this.btnList.filter((item, i) => {
          return item.title != "";
        });
      }
      let relationArr = [];
      for (let i = 0; i < this.result2.length; i++) {
        relationArr.push({ diagnostic_record_id: this.result2[i] });
      }
      if (this.btnList.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入至少一个特色标签！",
          duration: 1200,
          offset: 90,
        });
        this.btnList = [
          {
            title: "",
          },
          {
            title: "",
          },
          {
            title: "",
          },
        ];
        return false;
      }
      if (this.labelCheck.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案标签下的位置",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck1.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案标签下的问题类型",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      let arr = [];
      this.labelCheck.map((item) => {
        this.problems.map((data) => {
          if (data.position) {
            data.position.map((data1) => {
              if (item === data1.id) {
                if (
                  arr.findIndex((id1) => {
                    return id1.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id1) => {
                      return id1.id === data.id;
                    })
                  ].position.push({
                    ...data1,
                  });
                } else {
                  arr.push({
                    id: data.id,
                    title: data.title,
                    position: [{ ...data1 }],
                    problem: [],
                  });
                }
              }
            });
          }
        });
      });
      this.labelCheck1.map((item) => {
        this.problems.map((data) => {
          if (data.problem) {
            data.problem.map((data2) => {
              if (item === data2.id) {
                if (
                  arr.findIndex((id2) => {
                    return id2.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id2) => {
                      return id2.id === data.id;
                    })
                  ].problem.push({
                    ...data2,
                  });
                } else {
                  arr.push({
                    id: data.id,
                    title: data.title,
                    position: [],
                    problem: [{ ...data2 }],
                  });
                }
              }
            });
          }
        });
      });
      let mark = 0;
      arr.map((item) => {
        if (item.position.length > 0 && item.problem.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择方案标签下的问题类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
        if (item.problem.length > 0 && item.position.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择方案标签下的位置类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
      });
      if (mark != 1 && mark != 2) {
        this.problemArr = arr;
      } else {
        return false;
      }
      if (this.planIntro == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入方案简介！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      for (let i = 0; i < this.project.length; i++) {
        if (this.project[i].data.length == 0) {
          this.$message({
            showClose: true,
            type: "error",
            message: "清单项目不能为空",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }

      this.$loading(this.util.opload);
      // return;
      this.util
        .post(this.HOST + "/Diagnostic/SchemeMasterAdd", {
          schemeId: 0,
          type: this.nature,
          category: JSON.stringify(this.planKindArr),
          name: this.planName,
          communityName: JSON.stringify(this.house),
          houseType: JSON.stringify(this.houseType),
          day: this.timeLimit,
          characteristic: JSON.stringify(this.btnList),
          reminde: this.tip,
          pricing_rules: this.priceRule,
          service_content: this.serviceCon,
          materials_used: this.useMaterial,
          service_duration: this.serviceTime,
          completion: this.finished,
          quality: this.warrantyMsg,
          info: this.planIntro,
          tag: JSON.stringify(this.problemArr),
          project: JSON.stringify(this.project),
          // img: JSON.stringify(this.imgs).replace(/\[|]/g, "").replace(/\"/g, ""),
          img: JSON.stringify(this.imgs),
          schemeRelation: JSON.stringify(relationArr),
          cityId: this.city,
        })
        .then((res) => {
          if ((res.code = 200)) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setDialog", false);
              this.$emit("getDataAgain", this.currentPage);
              this.$emit("update:currentPage", this.currentPage);
            }, 1200);
          } else {
            this.$loading().close();
          }
        });
    },
    handleClose() {
      this.changeTitleDialog = false;
    },
    closeImg() {
      this.imgNum++;
      this.tudiag = false;
      this.dialogImageUrl = "";
    },
  },
};
</script>
<style scoped>
.liTitle {
  width: 70px;
  text-align: center;
}
.liTitle2 {
  width: 100px;
}
.littleBox input {
  height: 25px !important;
  line-height: 25px !important;
}
div /deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
}
div /deep/ .avue-crud__menu {
  display: none;
}
.titleChange {
  text-decoration: underline;
}
.titleChange:hover {
  color: orange;
}

.prDiv {
  width: 100%;
  margin: 0 auto;
}
.img {
  width: 100%;
}
</style>
